header {
  display: flex;
  border-bottom: 1px solid #C9CCCE;
}


header > div {
  display: flex;
}



header > div > img {
  padding: 10px;
}

